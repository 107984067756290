import { StateCreator } from 'zustand';

interface State {
  pinnedInspirations: string[];
}

export const initialInspirationsSlice: State = {
  pinnedInspirations: [],
};

interface Actions {
  setPinnedInspirations: (items: string[]) => void;
}

export interface InspirationsSlice extends State, Actions {}

export const createInspirationsSlice: StateCreator<
  InspirationsSlice,
  [],
  []
> = (set) => ({
  ...initialInspirationsSlice,
  setPinnedInspirations: (pinnedInspirations) =>
    set((state) => ({ ...state, pinnedInspirations })),
});
