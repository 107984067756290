export enum MarketingSources {
  FACEBOOK = 'facebook',
  TIKTOK = 'tiktok',
  GOOGLE = 'google',
  ALL = 'all',
  UNKNOWN = 'unknown',
}

export enum PlanPrice {
  tier_1 = 50,
  tier_2 = 75,
  tier_3 = 100,
  tier_4 = 150,
  earlyadopter = 50,
}

export enum TaskImage {
  BADGE_PERSON = 'badge',
  REVENUE_UP = 'revenue-up',
  REVENUE_DOWN = 'revenue-down',
  ROCKET = 'rocket',
  SIREN = 'siren',
  HOURGLASS = 'hourglass',
  SHOPPING_CART = 'shopping-cart',
}
export enum TaskCategory {
  BADGE_PERSON = 'badge',
  REVENUE_UP = 'revenue-up',
  REVENUE_DOWN = 'revenue-down',
  ROCKET = 'rocket',
  SIREN = 'siren',
  HOURGLASS = 'hourglass',
  SHOPPING_CART = 'shopping-cart',
}

export enum AdLevelTypes {
  CAMPAIGN = 'campaign',
  ADSET = 'adset',
  AD = 'ad',
}

export enum AdStatusUpdate {
  ENABLE = 'enable',
  DISABLE = 'disable',
}

export enum SubscriptionStatuses {
  ACTIVE = 'active',
  TRIALING = 'trialing',
  CANCELLED = 'cancelled',
  INACTIVE = 'inactive',
}

export enum AccountStates {
  FROZEN = 'frozen',
  ACTIVE = 'active',
  CANCELED = 'canceled',
}

export type InvoiceStatuses = 'paid' | 'uncollectable' | 'past_due';

export type Invoice = {
  id: string;
  customer_id: string;
  invoice_body: string;
  created_at: string;
  updated_at: string;
  status: InvoiceStatuses;
};

export enum TaskStatus {
  Pending = 'Pending',
  Viewed = 'Viewed',
  Snoozed = 'Snoozed',
  Complete = 'Complete',
}
